import React, { Component } from 'react';
// import {Link} from 'react-router-dom'

class TabProject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projects: [
                {
                    id: 1,
                    classnames: 'project-item green villa',
                    srcimg: 'assets/img/seker/paten.jpg',
                    name: 'PATENLİ SERVİS PERSONELİ'
                },
                {
                    id: 2,
                    classnames: 'project-item architecture building construction',
                    srcimg: 'assets/img/seker/cam.webp',
                    name: 'CAM TEMİZLEME PERSONELİ'
                },
                {
                    id: 3,
                    classnames: 'project-item villa architecture building construction',
                    srcimg: 'assets/img/seker/temizlik.jpg',
                    name: 'TEMİZLİK PERSONELİ'
                },
                {
                  id: 4,
                  classnames: 'project-item villa architecture building construction',
                  srcimg: 'assets/img/seker/Evde_Bakim.jpg',
                  name: 'HASTA BAKIM PERSONELİ'
                },
                {
                    id: 5,
                    classnames: 'project-item architecture building construction',
                    srcimg: 'assets/img/seker/barmen.jpg',
                    name: 'SHOW BARMEN'
                },
                {
                  id: 6,
                  classnames: 'project-item villa architecture building construction',
                  srcimg: 'assets/img/seker/bahce.webp',
                  name: 'BAHÇE PEYZAJ PERSONELİ'
                },
                {
                  id: 7,
                  classnames: 'project-item villa architecture building construction',
                  srcimg: 'assets/img/seker/ogretmen.jpg',
                  name: 'ÖZEL OKUL ÖĞRETMENİ'
                },
                {
                  id: 8,
                  classnames: 'project-item villa architecture building construction',
                  srcimg: 'assets/img/seker/camasir.webp',
                  name: 'ÇAMAŞIRHANE PERSONELİ'
                },
                {
                  id: 9,
                  classnames: 'project-item villa architecture building construction',
                  srcimg: 'assets/img/seker/hemsire.jpg',
                  name: 'HEMŞİRE'
                },
                {
                  id: 10,
                  classnames: 'project-item villa architecture building construction',
                  srcimg: 'assets/img/seker/egitim.jpeg',
                  name: 'EĞİTİM HİZMETLERİ'
                },
                {
                  id: 11,
                  classnames: 'project-item villa architecture building construction',
                  srcimg: 'assets/img/seker/oteldepartman.jpg',
                  name: 'OTEL DEPARTMAN MÜDÜRÜ'
                },
                {
                  id: 12,
                  classnames: 'project-item villa architecture building construction',
                  srcimg: 'assets/img/seker/paketleme.jpg',
                  name: 'PAKETLEME PERSONELİ'
                },
            ]
        }
    }

    render() {
        return (
            <div className="themesflat-project style-2 isotope-project has-margin mg15 data-effect clearfix">
                {
                this.state.projects.map(data =>(
                <div className={data.classnames} key={data.id}>
                    <div className="inner">
                        <div className="thumb data-effect-item has-effect-icon w40 offset-v-19 offset-h-49">
                            <img src={data.srcimg} alt="altimage" />
                            <h4>{data.name}</h4>
                            <div className="overlay-effect bg-color-3" />
                        </div>
                        {/* <div className="text-wrap">
                            <h5 className="heading">
                                <Link to="#">{data.heading}</Link>
                            </h5>
                            <div className="elm-meta">
                                <span>
                                    <Link to="#">{data.name01}</Link></span>
                                <span>
                                    <Link to="#">{data.name01}</Link></span>
                            </div>
                        </div> */}
                    </div>
                </div>
                ))
                }
            </div>
        );
    }
}

export default (TabProject);